import gql from 'graphql-tag';

export const GET_REFERRAL_CODE = gql`
  query getReferral($code: String) {
    referral(code: $code) @rest(type: "Referral", path: "/referral/{args.code}", endpoint: "cygnus") {
      code
      type
      sourceUuid
      creditAmount
      rewardAmount
      createdAt
      deepLink
      active
    }
  }
`;

export const GET_PUBLIC_USER = gql`
  query getPublicUser($sourceUuid: String) {
      publicUser(uuid: $sourceUuid) @rest(type: "PublicUser", path: "/user/{args.uuid}/public", endpoint: "as") {
        firstName
        joinedDate
      }
  }
`;

export const GET_MY_REFERRAL = gql`
  query getReferral($sourceUuid: String) {
    referral(uuid: $sourceUuid) @rest(type: "Referral", path: "/referral?sourceUuid={args.uuid}&referralType=USER", endpoint: "cygnus") {
      sourceUuid
      type
      active
      code
      creditAmount
      rewardAmount
      minBasketPrice
      deepLink
      createdAt
    }
  }
`;

export const GET_MOCK_REFERRAL = gql`
  query getMockReferral($code: string) {
    referral(code: $code, input: {}) @rest(method: "POST", type: "Promotion", path: "/referral/signup/mock?referralCode={args.code}", endpoint: "cygnus") {
      code
      userUuid
      summary
      details
      checkoutInfo
      fundingSource
      promotionSource
      sourceUuid
      type
      discountType
      value
      minBasketOrder
      oneTime
      active
      hidden
      expiryDate
    }
  }
`;
