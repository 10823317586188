import { logAnalytics, push } from './helper';

export const newReferralClick = (from: 'user_account_screen' | 'track_order_screen' | 'shop_banner' | 'shop_pop_up') => {
  logAnalytics('referrals_clicked', {
    from_where: from
  });
};

export const newReferralShare = (method: 'copy_link' | 'shared_deep_link' | 'email' | 'facebook' | 'twitter') => {
  logAnalytics('referrals_shared', {
    method: method
  });
};
